import React from "react";
import EventCard from "../components/EventCard";

function FeaturesBlocks() {
  return (
    <section className="relative overflow-hidden">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-purple-700 pointer-events-none"
        aria-hidden="true"
      >
        <img src={require("../images/white-textured.png")} />
      </div>
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Episódios</h2>
            <p className="text-xl text-gray-600">
              Temos lives todas as terças e quintas entre as 17h e 20h, que
              depois é condensado em vídeo e áudio. Para nos acompanhar ao vivo
              e interagir fique ligado na nossa agenda para saber sobre os
              próximos bate papos.
            </p>
          </div>

          <div className="w-full flex">
            {/* <EventCard
              day={"25"}
              month={"03"}
              time={"18:00"}
              title={"Dênio Júnior - DevOps SRE "}
              description=""
              thumbnail={require("../images/speakers/docker-terraform.png")}
              speaker={{
                image: require("../images/speakers/denio-junior.jpg"),
                name: "Dênio Júnior",
                role: "DevOps SRE - Hotmart",
              }}
            /> */}
            <EventCard
              day={"13"}
              month={"04"}
              time={"18:00"}
              title={"David Roberts - Carreira internacional para devs"}
              description=""
              thumbnail={require("../images/speakers/david.png")}
              speaker={{
                image: require("../images/speakers/david.png"),
                name: "David Roberts",
                role: "Managing Director na Crushing Digital (EN)",
              }}
            />
            {/* <EventCard
              day={"31"}
              month={"03"}
              time={"18:00"}
              title={"Lucas Carneiro - Coach de Carreira"}
              thumbnail={require("../images/speakers/carreira-dev.jpg")}
              description=""
              speaker={{
                image: require("../images/speakers/denio-junior.jpg"),
                name: "Dênio Júnior",
                role: "DevOps SRE - Hotmart",
              }}
            /> */}
            {/* <EventCard
              day={"20"}
              month={"04"}
              time={"19:00"}
              title={"Marcos Jonatan Suriani - Gerente de Tecnologia"}
              description=""
              thumbnail={require("../images/speakers/arquiteturas.jpg")}
              speaker={{
                image: require("../images/speakers/denio-junior.jpg"),
                name: "Marcos Jonatan Suriani",
                role: "Gerente de Tecnologia - Linx",
              }}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;

import React from "react";

export default ({
  title,
  description,
  isNext,
  thumbnail,
  speaker,
  day,
  month,
  time,
  now,
}) => {
  return (
    <div className="w-full  p-4">
      <a
        href="https://www.youtube.com/watch?v=2l9YX1DlT2o"
        className="c-card block bg-white shadow-xl rounded-lg overflow-hidden"
      >
        <div className="relative pb-48 overflow-hidden">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={thumbnail}
            alt=""
          />
        </div>
        <div className="p-4">
          <span className="inline-block py-1 leading-none bg-orange-200 italic text-orange-800 rounded-full font-semibold  tracking-wide text-xs">
            {now ? (
              <a
                href="https://www.youtube.com/watch?v=2l9YX1DlT2o"
                className="text-green-700 font-bold"
              >
                Ao Vivo agora
              </a>
            ) : (
              `Transmissão ao vivo em ${day}/${month} ${time}h`
            )}
          </span>
          <h2 className="mt-2 mb-2  font-bold">{title}</h2>
          <p className="text-sm">{description}</p>
          <div className="mt-3 flex items-center"></div>
        </div>
        <div className="p-4 border-t border-b text-xs text-gray-700"></div>
      </a>
    </div>
  );
};

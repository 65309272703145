import React, { useState } from "react";
import tw from "twin.macro";

import Modal from "../utils/Modal";
import Logo from "../images/Logo.png";

const LogoContainer = tw.div`w-full h-72 flex justify-center z-10`;

function HeroHome() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className="relative">
      <div
        className="absolute bg-gray-100 flex w-full h-auto z-0 left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
        aria-hidden="true"
      >
        <img src={require("../images/grey-textured.png")} />
      </div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 z-10">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="text-center pb-12 md:pb-16">
            <LogoContainer>
              <img className="w-72" src={Logo} />
            </LogoContainer>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;

import React from "react";

function About() {
  return (
    <section className="relative">
      {/* Illustration behind content */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4">Quem faz</h2>
            {/* <p className="text-xl text-gray-600" data-aos="zoom-y-out">
              Quer fazer parte do Marreta Podcast? Acesse aqui.
            </p> */}
          </div>

          {/* Testimonials */}
          <div className="max-w-3xl mx-auto mt-20" data-aos="zoom-y-out">
            <div className="relative flex items-start border-2 border-gray-200 rounded bg-white">
              {/* Testimonial */}
              <div className="text-center px-12 py-8 pt-20 mx-4 md:mx-0">
                <div className="absolute top-0 -mt-8 left-1/2 transform -translate-x-1/2">
                  <img
                    className="relative rounded-full"
                    src={require("../images/vinicius-lana.jpg")}
                    width="96"
                    height="96"
                    alt="Testimonial 01"
                  />
                </div>
                <blockquote className="text-xl font-medium mb-4">
                  Criei o Marreta Podcast no intuito de transferir aprendizado
                  de forma leve a descontraída, tornando o conhecimento sobre
                  tecnologia cada vez mais democrático e acessível.
                  <blockquote className=" font-medium italic text-base mb-4">
                    “Feliz aquele que transfere o que sabe e aprende o que
                    ensina“ - Cora Coralina.
                  </blockquote>
                </blockquote>
                <cite className="block font-bold text-lg not-italic mb-1">
                  Vinicius Lana
                </cite>
                <div className="text-gray-600 flex flex-col justify-center items-center">
                  <span>Senior Front End Engineer</span>
                  <a
                    className="text-blue-600 hover:underline"
                    href="https://x-team.com/"
                    target="_blank"
                  >
                    <img
                      className="mt-2"
                      src={require("../images/X-Team.svg")}
                      width="70"
                      height="70"
                      alt="X-Team"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
